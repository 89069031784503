import React from "react";
import { googleMapConstants } from "./mapConstants";
import { APILoadingStatus, Map, useApiLoadingStatus } from "@vis.gl/react-google-maps";
import { VisibilityWrapper } from "components/layout";
import { EmptyState } from "components/appState";
import { useAOSFlavour } from "hooks/app/multitenant/useAosFlavour";

const GoogleMap = ({ width, height, ...restProps }) => {
  const status = useApiLoadingStatus();

  const { aosFlavour } = useAOSFlavour();

  return (
    <VisibilityWrapper
      isVisible={status === APILoadingStatus.LOADED && aosFlavour === "local"}
      placeholder={<EmptyState title="Loading Map..." />}
      height={height}
      width={width}
      borderRadius="1rem"
      overflow="hidden"
    >
      <Map {...googleMapConstants.defaults.MAP_OPTIONS} {...restProps}></Map>
    </VisibilityWrapper>
  );
};

export default GoogleMap;
